// Utils
import { fadeIntoView } from '../../../utils/animations';

/**
 * `getAnimationProps` Function
 *
 * @remarks
 * Determines the animation properties for content. Uses content type and viewport
 * size to set animation direction and delay.
 *
 * @param animated - If `true`, applies animation.
 * @param viewportSize - Describes viewport size (e.g., "small", "medium").
 * @param isImageContent - If `true`, sets animation direction to 'bottom'.
 *
 * @returns Object with animation properties.
 *
 * @example
 * ```tsx
 * const animation = getAnimationProps(true, 'small', true);
 * ```
 */
export function getAnimationProps(
  animated: boolean | undefined,
  isMobile: boolean,
  semanticType?: string,
  isImageContent?: boolean
) {
  if (!animated) return {};
  return isImageContent
    ? fadeIntoView({ fadeDirection: 'bottom' })
    : semanticType === 'span'
    ? fadeIntoView({ fadeDirection: 'top', delay: 0.6 })
    : fadeIntoView({
        fadeDirection: 'top',
        translatePercentage: isMobile ? 1.5 : 3,
        margin: isMobile ? '50px' : '0px',
      });
}
