/**
 * `getCombinedClasses` Function
 *
 * @remarks
 * Constructs a class string from base and optional classes. Useful for dynamic
 * class generation based on component props or state.
 *
 * @param baseClasses - List of primary class names.
 * @param options - Object with keys as class names and values as booleans or strings.
 *
 * @returns Concatenated class names.
 *
 * @example
 * ```tsx
 * const classNames = getCombinedClasses(['base'], { isActive: true, size: 'large' });
 * // Result: "base isActive largeSize"
 * ```
 */
export function getCombinedClasses(
  baseClasses: (string | undefined)[],
  options: { [key: string]: undefined | boolean | string }
): string {
  const derivedClasses = Object.entries(options).map(([key, value]) => {
    if (value === undefined) {
      return null;
    }
    if (typeof value === 'boolean' && value) {
      return key;
    }
    if (typeof value === 'string') {
      return `${value}${key.charAt(0).toUpperCase() + key.slice(1)}`;
    }
    return null;
  });

  return [...baseClasses, ...derivedClasses]
    .filter((value) => typeof value === 'string')
    .join(' ');
}
