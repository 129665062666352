// External libraries
import { motion } from 'framer-motion';
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

// Hooks
import useViewportSize from '../../../features/viewportSize/hook/useViewportSize';

// Utils
import { getCombinedClasses } from '../utils/getCombinedClasses';
import { getAnimationProps } from '../utils/getAnimationProps';

// Components and Types
import ContentContainer, {
  ContentContainerProps,
} from '../contentContainer/contentContainer';
import { Size } from '../../../utils/types';

// Styles
import '../containers.css';
import './container.css';

/**
 * `Container` Component
 *
 * @remarks
 * The `Container` is a versatile layout component. It offers styling, animations,
 * and semantic rendering based on the provided properties. It ensures consistent
 * design across the application.
 *
 * @param children - React nodes inside the container.
 * @param padded - Applies padding if `true`. Defaults to `true`.
 * @param animated - If `true`, applies entrance animation.
 * @param gap - Defines the gap size (e.g., "small", "medium"). Defaults to `medium`.
 * @param contentContainerProps - Props for the `ContentContainer`.
 * @param noContentContainer - If `true`, avoids wrapping children in `ContentContainer`.
 * @param semanticType - Type of semantic HTML (e.g., "header", "main", "section").
 * @param endContainer - If `true`, applies end container-specific styles.
 * @param className - Additional classes for the container.
 * @param ...passedProps - Additional props for the container.
 *
 * @example
 * ```tsx
 * <Container semanticType="section" padded={false} animated>
 *   <h1>Hello World</h1>
 * </Container>
 * ```
 */
function Container(
  {
    children,
    padded = true,
    animated,
    gap = 'medium',
    contentContainerProps,
    noContentContainer,
    semanticType,
    endContainer,
    className: passedClasses,
    ...passedProps
  }: ContainerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  // define animation props
  const { mobile } = useViewportSize();
  const animation = getAnimationProps(animated, mobile, semanticType);

  // derive classes
  const className = getCombinedClasses(['container', passedClasses], {
    padded,
    endContainer,
    gap,
  });

  // define props
  const props = { ...passedProps, className, ...animation };

  // define content
  const content = noContentContainer ? (
    children
  ) : (
    <ContentContainer {...contentContainerProps}>{children}</ContentContainer>
  );

  // derive semantic element
  const SemanticElement = semanticType ? motion[semanticType] : motion.section;

  return (
    <SemanticElement ref={ref} {...props}>
      {content}
    </SemanticElement>
  );
}

/**
 * Type definition for the properties expected by the Container component.
 */
export type ContainerProps = {
  padded?: boolean;
  animated?: boolean;
  gap?: Size;
  semanticType?: 'header' | 'main' | 'section' | 'div';
  endContainer?: boolean;
} & ComponentPropsWithRef<typeof motion.div> &
  OptionalContentContainerProps;

type OptionalContentContainerProps =
  | {
      noContentContainer: true;
      contentContainerProps?: never;
    }
  | {
      noContentContainer?: false;
      contentContainerProps?: ContentContainerProps;
    };

export default forwardRef<HTMLDivElement, ContainerProps>(Container);
