// External libraries
import { motion } from 'framer-motion';
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

// Hooks
import useViewportSize from '../../../features/viewportSize/hook/useViewportSize';

// Utils
import { getCombinedClasses } from '../utils/getCombinedClasses';
import { getAnimationProps } from '../utils/getAnimationProps';

// Types
import { Size } from '../../../utils/types';

// Styles
import '../containers.css';
import './contentContainer.css';

/**
 * `ContentContainer` Component
 *
 * @remarks
 * `ContentContainer` is a structural wrapper for content. It offers animation,
 * styling options, and ensures consistent design in a broader design system.
 *
 * @param children - React nodes as children.
 * @param animated - If `true`, content has an entrance animation.
 * @param semanticType - HTML type for rendering (defaults to 'div').
 * @param endContainer - If `true`, applies end container styles.
 * @param cardContent - If `true`, content is specific to a card.
 * @param imageContent - If `true`, content is image-based, affecting animations.
 * @param gap - Defines gap size (e.g., "small", "medium"). Defaults to 'small'.
 * @param className - Additional classes.
 * @param ...passedProps - Additional props.
 *
 * @example
 * ```tsx
 * <ContentContainer semanticType="section" animated cardContent>
 *   <h2>Card Title</h2>
 *   <p>This is card content.</p>
 * </ContentContainer>
 * ```
 */

function ContentContainer(
  {
    children,
    animated,
    semanticType = 'div',
    endContainer,
    cardContent,
    imageContent,
    gridDisplay,
    autoRows = !!gridDisplay,
    gap = 'small',
    className: passedClasses,
    ...passedProps
  }: ContentContainerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  // define animation props
  const { mobile } = useViewportSize();
  const animation = getAnimationProps(
    animated,
    mobile,
    semanticType,
    imageContent
  );

  // derive classes
  const className = getCombinedClasses(
    [
      'baseContentContainer',
      passedClasses,
      semanticType === 'span' ? 'spanInitialWidth' : undefined,
    ],
    {
      cardContent,
      imageContent,
      gridDisplay,
      autoRows,
      endContainer,
      gap,
    }
  );

  // define props
  const props = { ...passedProps, className, ...animation };

  // derive semantic element
  const SemanticElement = semanticType ? motion[semanticType] : motion.div;

  return (
    <SemanticElement ref={ref} {...props}>
      {children}
    </SemanticElement>
  );
}

/**
 * Type definition for the properties expected by the ContentContainer component.
 */
export type ContentContainerProps = {
  animated?: boolean;
  endContainer?: boolean;
  semanticType?: 'article' | 'div' | 'span';
  cardContent?: boolean;
  imageContent?: boolean;
  gap?: Size;
} & GridDisplay &
  ComponentPropsWithRef<typeof motion.div>;

type GridDisplay =
  | {
      gridDisplay: true;
      autoRows?: boolean;
    }
  | {
      gridDisplay?: false;
      autoRows?: never;
    };

export default forwardRef<HTMLDivElement, ContentContainerProps>(
  ContentContainer
);
