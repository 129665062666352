// External libraries
import { ForwardedRef, SVGAttributes, forwardRef } from 'react';
import { MotionProps, motion } from 'framer-motion';

// Types
import { Direction } from '../../utils/types';

/**
 * `ArrowIcon` is a functional component that renders an arrow SVG icon,
 * which can point in one of four directions ('top', 'right', 'bottom', or 'left'),
 * depending on the `pointDirection` prop.
 *
 * The direction is implemented by rotating the SVG
 *
 * @component
 * @param props - The properties that define the direction in which the arrow points.
 * @returns {ReactElement} The rendered ArrowIcon SVG.
 *
 * @example
 * return (
 *   <ArrowIcon pointDirection="right" />
 * )
 */
function ArrowIcon(
  {
    pointDirection,
    style,
    ...rest
  }: {
    pointDirection: Direction;
  } & MotionProps &
    SVGAttributes<SVGSVGElement>,
  ref: ForwardedRef<SVGSVGElement>
) {
  // Map direction to corresponding rotation angle
  const directionToRotation = {
    top: '0',
    right: '90',
    bottom: '180',
    left: '-90',
  };

  return (
    <motion.svg
      ref={ref}
      id="arrowIcon"
      style={{ rotate: `${directionToRotation[pointDirection]}deg`, ...style }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.5035 10.9355C10.6363 10.9947 10.7131 11.0397 10.825 11.0433C10.8806 11.0449 10.936 11.0356 10.9879 11.0157C11.0399 10.9959 11.0875 10.9659 11.1278 10.9277C11.1682 10.8894 11.2007 10.8435 11.2233 10.7927C11.2459 10.7419 11.2582 10.6871 11.2596 10.6314C11.262 10.5196 11.278 10.4679 11.1866 10.2882C11.0952 10.1096 6.9427 1.11577 6.9427 1.11577C6.87678 0.972012 6.77193 0.849586 6.64002 0.762352C6.50811 0.675118 6.3544 0.626562 6.19632 0.622182C6.03823 0.617803 5.88207 0.657774 5.74553 0.73757C5.60899 0.817366 5.49752 0.933799 5.42374 1.07368C5.42374 1.07368 0.798178 9.9318 0.732101 10.0486C0.666023 10.1655 0.634885 10.2657 0.631859 10.3781C0.628844 10.4904 0.670545 10.5993 0.747789 10.6808C0.825033 10.7623 0.931494 10.8098 1.04375 10.8128C1.15564 10.8152 1.20091 10.7971 1.36877 10.7205L5.984 8.63355C5.984 8.63355 10.3714 10.8768 10.5035 10.9355Z"
        fill="#1E1E1E"
        stroke="white"
        strokeWidth="0.855476"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}

type ArrowIconProps = {
  pointDirection: Direction;
} & MotionProps &
  SVGAttributes<SVGSVGElement>;

export default forwardRef<SVGSVGElement, ArrowIconProps>(ArrowIcon);
