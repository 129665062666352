// Types
import { MotionProps } from 'framer-motion';

// utils
import { ease } from '../../../utils/animations';

// Define component base animations
export const baseAnimation: Required<
  Pick<MotionProps, 'initial' | 'whileHover' | 'transition' | 'whileTap'>
> = {
  initial: { borderColor: 'rgba(255,255,255, 0)' },
  whileHover: { borderColor: 'rgba(255,255,255, 0.2)' },
  transition: { duration: 0.5, easings: ease },
  whileTap: { backgroundColor: 'rgba(0,0,0, 0.8)' },
};
