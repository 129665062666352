// External libraries
import { motion } from 'framer-motion';
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

// Utils
import { camelCaseToSpaced } from '../../utils/functions/camelCaseToSpaced';

/**
 * `getIcon` Function
 *
 * @remarks
 * The `getIcon` function is a utility designed to retrieve an SVG icon based on a given
 * icon name. It constructs the path for the SVG icon file and returns an `<img>` element
 * displaying that icon. The function also uses a helper function, `camelCaseToSpaced`,
 * to dynamically generate a readable and user-friendly alt text for the icon.
 *
 * ## Features:
 * 1. **Icon Retrieval**: Dynamically constructs the path for an icon based on the provided icon name.
 * 2. **Alt Text Conversion**: Converts camel-cased icon names to a spaced format for better accessibility.
 * 3. **Fallback**: If no icon name is provided or if it's undefined, the function simply returns without
 *    generating any image element.
 *
 * @param iconName - A string representing the name of the icon to retrieve. It can be undefined.
 *
 * @returns An `<img>` React element that displays the desired icon with a dynamically generated alt text.
 *          If no `iconName` is provided or if it's undefined, the function returns undefined.
 *
 * @example
 * ```tsx
 * const UserIcon = getIcon('userProfile');
 * // Renders: <img src="/icons/userProfile.svg" alt="user profile icon" />
 * ```
 *
 * ## Usage Notes:
 * - Icons should be stored in the `/icons/` directory and should have a `.svg` extension.
 * - The function assumes that icons have the `.svg` extension and are named based on the `iconName` provided.
 * - The alt text for the icon is generated using `camelCaseToSpaced` function, which is assumed to convert
 *   camel-case strings to a spaced format.
 *
 */
function Icon(
  { animated, iconName, webp, ...rest }: IconProps,
  ref: ForwardedRef<HTMLImageElement>
) {
  if (!iconName) return null;
  const baseAnimation = animated ? { whileHover: { scale: animated } } : {};
  return (
    <motion.img
      ref={ref}
      src={`/icons/${iconName}.${webp ? 'webp' : 'svg'}`}
      alt={`${camelCaseToSpaced(iconName)} icon`}
      {...baseAnimation}
      {...rest}
    />
  );
}

type IconProps = {
  iconName?: string;
  animated?: 1.1 | 1.2;
  webp?: boolean;
} & ComponentPropsWithRef<typeof motion.img>;

export default forwardRef<HTMLImageElement, IconProps>(Icon);
