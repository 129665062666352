// External libraries
import { ComponentType, createElement } from 'react';

/**
 * Renders a React component.
 *
 * @remarks
 * This utility function dynamically creates a React component based on the
 * specified element type. It's typically used when the exact type of component
 * to render can vary, but the props and children remain consistent.
 *
 * @param element - The component type or HTML element to be rendered.
 *                       Its exact type is not known at the function's definition,
 *                       hence marked as `unknown`.
 * @param props - The properties to pass to the created component.
 * @param children - The children elements or content for the created component.
 *
 * @returns A rendered React component.
 *
 * @example
 * ```tsx
 * const MyComponent = getElement(SomeComponentType, { id: "123" }, <span>Hello</span>);
 * ```
 */
export function getElement(element: unknown, props: {}, children: JSX.Element) {
  return createElement(element as ComponentType, props, children);
}
