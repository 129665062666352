// External libraries
import { motion } from 'framer-motion';
import MotionLink from '../../motionLink/motionLink';

/**
 * Base elements for the Chip component.
 *
 * @remarks
 * Defines the possible underlying elements for a Chip component based on
 * its intended usage. These base elements provide the foundation for the
 * Chip's rendering, and their behaviors are enhanced with `motion` properties
 * to provide fluid animations and transitions.
 *
 * - **button**: Typically used for Chip elements that need to trigger
 *    an action, like a click event.
 * - **anchor**: Intended for Chips that navigate or link to other resources.
 * - **static**: A simple div element for Chips that don't require interactivity.
 *
 * These elements can be paired with `motion` from the `framer-motion` library
 * to animate the Chip during interactions or when it enters/leaves the view.
 *
 * @example
 * ```tsx
 * const ButtonChip = createElement(chipBaseElements.button, props, children);
 * ```
 */
export const chipBaseElements = {
  button: motion.button,
  anchor: MotionLink,
  static: motion.div,
};
