// Types
import { ChipProps } from './utils/types';

// Utils
import { getElement } from '../../utils/functions/getElement';
import { capitalize } from '../../utils/functions/capitalize';
import { chipBaseElements } from './utils/chipBaseElements';

// Styles
import './chip.css';
import { baseAnimation } from './utils/baseAnimation';
import { ForwardedRef, forwardRef } from 'react';

/**
 * `Chip` Component
 *
 * @remarks
 * The `Chip` component is a versatile UI element, often used to display tags,
 * labels, or interactive buttons with a concise piece of information.
 *
 * Based on the `use` property, the Chip can behave as:
 * 1. A clickable button
 * 2. A navigational anchor/link
 * 3. A static, non-interactive div
 *
 * @param use - Determines the type of the chip: 'button', 'anchor', or 'static'.
 * @param icon - An optional icon displayed within the chip.
 * @param iconPosition - Determines where the icon is placed: 'left' or 'right'.
 * @param baseStyling - If true, applies the default chip styles.
 * @param className - Optional CSS classes to be added to the chip.
 * @param altBG - If true, applies an alternative background style.
 * @param title - The main text or title of the chip.
 * @param children - Additional content or elements within the chip.
 * @param rest - All other properties passed down to the rendered element.
 *
 * @returns A rendered `Chip` component.
 *
 * @example
 * ```tsx
 * <Chip use="button" title="Click Me" icon={<MyIcon />} />
 * ```
 */
function Chip(
  {
    use,
    icon,
    iconPosition,
    baseStyling,
    className: passedClasses = '',
    altBG,
    title,
    children,
    ...rest
  }: ChipProps,
  ref: ForwardedRef<HTMLElement>
) {
  // Construct the complete CSS class name
  const className = [
    passedClasses,
    altBG && 'altBG',
    baseStyling && 'chip',
    iconPosition && `iconPosition${capitalize(iconPosition)}`,
  ]
    .filter(Boolean)
    .join(' ');

  // Prepare the content of the Chip, including the optional icon
  const content = (
    <>
      {iconPosition !== 'right' && icon}
      {title}
      {children}
      {iconPosition === 'right' && icon}
    </>
  );

  // Merge base animations, additional props, and class names
  const props = {
    ...baseAnimation,
    ...rest,
    className,
    ref,
  };

  // render element
  return getElement(chipBaseElements[use], props, content);
}

export default forwardRef<HTMLElement, ChipProps>(Chip);
